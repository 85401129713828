import styled from "styled-components";

const shipperInfoContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: flex-start;
  align-self: stretch;
`;

const shipperInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 272px;
`;

const shipperInfoItem = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;
  height: ${(props) => (props?.height ? `${props?.height}px` : "70px")};

  > .border-top {
    border-top: 1px solid #5482d6;
    width: 272px;
  }

  > .title {
    color: #4d70c3;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  > .partner-name {
    font-size: 9px;
  }
`;

const notifyPartyAndGoodsApplyToContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const logoContainer = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  width: 272px;
`;

const companyInfo = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  align-self: stretch;
`;

const logo = styled.img`
  width: 108px;
  height: 44px;
`;

const companyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

const companyName = styled.div`
  color: #4f6575;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.192px;
  font-weight: 700;
`;

const addressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
`;

const address = styled.div`
  color: #424242;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: -0.048px;
`;

const contactInfo = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const label = styled.div`
  color: #757575;
  font-size: 7px;
  line-height: 7px;
  letter-spacing: -0.042px;
  font-weight: 700;
`;

const phoneNumber = styled.div`
  color: #424242;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: -0.048px;
`;

const carrierInfo = styled.div`
  color: #757575;
  font-size: 6px;
  line-height: 8px;
  letter-spacing: -0.105px;
  align-self: stretch;
`;

export default {
  shipperInfoContainer,
  shipperInfoColumn,
  shipperInfoItem,
  notifyPartyAndGoodsApplyToContainer,
  logoContainer,
  companyInfo,
  logo,
  companyDetails,
  companyName,
  addressContainer,
  address,
  contactInfo,
  label,
  phoneNumber,
  carrierInfo,
};
